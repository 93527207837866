import { useQuery } from '@apollo/client';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CancelIcon from '@mui/icons-material/Cancel';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { requestingPlatformMapperIcon, SiiIcon } from '../assets';
import { GET_REQUESTING_PLATFORMS } from '../graphql';

const quickButtons = () => {
  const arraysEqual = (a, b) => {
    const setA = new Set(a);
    const setB = new Set(b);
    return setA.size === setB.size
      && [...setA].every((x) => setB.has(x));
  };
  const buttons = [
    {
      id: 'rejected',
      key: 'siiStatus',
      title: 'Rechazadas',
      value: ['Rejected'],
      EndIcon: SiiIcon,
      color: 'red',
      defaultValue: [],
      getIsActive: (actualStates) => actualStates.siiStatus?.includes('Rejected'),
    },
    {
      id: 'payed',
      key: 'documentFinanceStatus',
      title: 'Pagadas',
      value: ['6', '7'],
      EndIcon: AttachMoney,
      color: 'green',
      defaultValue: [],
      getIsActive: (actualStates) => arraysEqual(actualStates.documentFinanceStatus, ['6', '7']),
    },
    {
      id: 'evaluationRejected',
      key: 'preoffer_Preofferevaluationrequest_Status',
      title: 'Rechazadas',
      value: 'Rejected',
      EndIcon: CancelIcon,
      color: 'red',
      defaultValue: null,
      getIsActive: (actualStates) => actualStates.preoffer_Preofferevaluationrequest_Status === 'Rejected',
    },
    {
      id: 'evaluationInProcess',
      key: 'preoffer_Preofferevaluationrequest_Status',
      title: 'Evaluando',
      value: 'Evaluating',
      EndIcon: PsychologyIcon,
      color: 'info',
      defaultValue: null,
      getIsActive: (actualStates) => actualStates.preoffer_Preofferevaluationrequest_Status === 'Evaluating',
    },
  ];

  return buttons;
};

const useQuickFilterButtons = () => {
  const { data: requestingPlatforms } = useQuery(GET_REQUESTING_PLATFORMS);
  const platforms = requestingPlatforms?.getRequestingPlatforms || [];
  const selectableButtons = quickButtons();
  platforms.forEach((platform) => {
    const PlatformIcon = requestingPlatformMapperIcon[platform.code];
    if (PlatformIcon) {
      selectableButtons.push(
        {
          id: `requestingPlatform_${platform.code}`,
          key: 'operation_RequestingPlatform_Code',
          title: platform.code,
          value: platform.code,
          EndIcon: PlatformIcon,
          color: 'info',
          defaultValue: null,
          // eslint-disable-next-line max-len
          getIsActive: (actualStates) => actualStates.operation_RequestingPlatform_Code === platform.code,
        },
      );
    }
  });

  return selectableButtons;
};

export default useQuickFilterButtons;
