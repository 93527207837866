import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_USER, MARK_TUTORIAL_SEEN } from '../graphql';
import useBooleanState from './useBooleanState';

const useTutorialHandler = (tutorialCode, isReady = true) => {
  const [runGuide, toggleRunGuide] = useBooleanState(false);
  const { data: { getUser: { userseentutorialSet } } = {} } = useQuery(GET_USER);
  const [markTutorialSeen] = useMutation(MARK_TUTORIAL_SEEN, {
    variables: { tutorialCode },
  });

  useEffect(() => {
    const tutorial = userseentutorialSet.filter(
      (_tutorial) => _tutorial.tutorialCode === tutorialCode && _tutorial.seen,
    );
    if (!tutorial.length && isReady) {
      toggleRunGuide();
      markTutorialSeen();
    }
  }, [userseentutorialSet, tutorialCode, toggleRunGuide, isReady]);

  return [runGuide, toggleRunGuide];
};

export default useTutorialHandler;
