import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '../apollo/reactive-variables';
import { useFilteredQuery } from '.';

const useSelectedOfferInvoices = (query) => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const offerIds = shoppingCartOffers.map((offer) => offer.invoiceId);
  const { data: offers, deleteQueryFromCache } = useFilteredQuery(
    query,
    {
      variables: { id_In: offerIds, first: 100 },
      skip: !offerIds.length,
    },
  );
  const invoices = offers?.invoices.edges.map(({ node }) => node) || [];
  return [invoices, offerIds, deleteQueryFromCache];
};

export default useSelectedOfferInvoices;
