/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from 'react';

const varName = 'useSavedState';

const useSavedState = (initialValue, nameVariable) => {
  const hasAllKeys = useCallback((savedValue) => {
    const initialKeys = Object.keys(initialValue).filter((key) => initialValue[key] !== undefined);
    return initialKeys.every((key) => Object.prototype.hasOwnProperty.call(savedValue, key));
  }, [initialValue]);
  const getSavedValues = useCallback(() => {
    const savedValues = JSON.parse(localStorage.getItem(varName));
    if (savedValues === null) {
      localStorage.setItem(varName, '{}');
      return {};
    }
    return savedValues;
  }, []);
  const saveValues = useCallback((newValue) => {
    const savedValues = getSavedValues();
    savedValues[nameVariable] = newValue;
    localStorage.setItem(varName, JSON.stringify(savedValues));
  }, [getSavedValues, nameVariable]);
  const primaryValue = useCallback(() => {
    const values = getSavedValues();
    if (values[nameVariable] === undefined) return initialValue;
    if (values[nameVariable] && !hasAllKeys(values[nameVariable])) {
      saveValues(initialValue);
      return initialValue;
    }
    return values[nameVariable];
  }, [nameVariable, initialValue, getSavedValues, hasAllKeys, saveValues]);
  const [state, setState] = useState(primaryValue());
  const proxySetState = useCallback((dispatcher) => {
    setState((prevValue) => {
      if (typeof dispatcher === 'function') {
        saveValues(dispatcher(prevValue));
        return dispatcher(prevValue);
      }
      saveValues(dispatcher);
      return dispatcher;
    });
  }, [saveValues]);
  return [state, proxySetState];
};

export default useSavedState;
