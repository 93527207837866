import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { TextTermSection, TableTermSection } from '../components/terms';
import useGetCountryFromUrl from './useGetCountryFromUrl';
import { TERMS_AND_CONDITIONS } from '../graphql';

const useTermAndConditionsSections = () => {
  const country = useGetCountryFromUrl();
  const { loading, data, error } = useQuery(TERMS_AND_CONDITIONS, {
    variables: { countryId: country?.id },
  });

  const termSections = useMemo(() => {
    if (!data) return [];

    const sections = data?.termsAndConditions?.edges?.flatMap((edge) => edge?.node?.sections) || [];

    return sections.map((section) => {
      const { id, text, sectionType } = section;
      if (sectionType === 'table') {
        return <TableTermSection key={id} id={id} text={text} />;
      }
      return <TextTermSection key={id} id={id} text={text} sectionType={sectionType} />;
    });
  }, [data]);

  return {
    loading,
    error,
    termSections,
  };
};

export default useTermAndConditionsSections;
